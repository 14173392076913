/**
 * Verify JavaScript.
 * © 2012 CloudV.cn ( Jemy@dongyuinfo.com )
 */

// const validate = {

//验证是否为空
// eslint-disable-next-line no-unused-vars
export function Verify_Empty(value, msg) {
  if (value == undefined || value == null || value == "" || value.length == 0) {
    // if (typeof (msg)=="undefined" || msg==null || $.trim(msg)=='' || msg== null){
    // }else {
    //     layer.msg(msg+'不能为空');
    // }
    return true;
  } else {
    return false;
  }
}
//对象是否为空
export function isEmptyObject(obj) {
  for (var key in obj) {
    return false; //返回false，不为空对象
  }
  return true; //返回true，为空对象
}

//验证email格式的规范性
export function Verify_Email(value) {
  return /^([a-zA-Z0-9]+[_|-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|-|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/gi.test(
    value
  );
  //return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(value);
}

//验证用户名格式的规范性 只能包含英文，数字，下划线，4-16位
export function Verify_UserName(value) {
  return /^[a-zA-Z0-9_]{4,16}$/gi.test(value);
}

//验证昵称格式的规范性 只能由大小写英文字母、中文、数字组成
export function Verify_NickName(value) {
  return /^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value);
}

//验证用户密码格式的规范性：6-20位，区分大小写
export function Verify_Password(value) {
  return /^.{6,16}$/.test(value);
}

//验证圈子名称格式的规范性 只能包含汉字，英文，数字，下划线，2字符以上
export function Verify_GroupName(value) {
  //第一部分：汉字   第二部分：半角   第三部分：数字和英文
  // eslint-disable-next-line no-useless-escape
  return /^([\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[_\a-zA-Z0-9]|[\s]){2,}$/gi.test(
    value
  );
}

//验证微博号格式的规范性：至少3位，第一位也不能为0
export function Verify_WeiboNumber(value) {
  return /^[1-9]\d{2,}$/.test(value);
}

//验证URL格式的规范性：http(s)://www.xxx.xxx
export function Verify_URL(value) {
  return /^(http[s]?):\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/i.test(value);
}

//验证路径格式的规范性：匹配象/path/path/的格式,必须以/开头/结尾
export function Verify_Path(value) {
  return /^\/([a-z0-9_-]+\/)*$/i.test(value);
}

//验证全数字格式的规范性
export function Verify_Digital(value) {
  return /^[0-9]*$/.test(value);
}
//验证全数字格式的规范性，第一位不能为0
export function Verify_Digitel(value) {
  return /^[1-9]\d*$/.test(value);
}
//验证汉字格式的规范性
export function Verify_Chinese(value) {
  return /^[u4e00-u9fa5],{0,}$/.test(value);
}
//验证电话号码格式的规范性  ：“XXXX-XXXXXXX”，“XXXX-XXXXXXXX”，“XXX-XXXXXXX”，   “XXX-XXXXXXXX”，“XXXXXXX”，“XXXXXXXX”。
export function Verify_Telephone(value) {
  // eslint-disable-next-line no-useless-escape
  return /^[0-9]{3,4}\-[0-9]{7,8}$/.test(value);
}
//验证手机号码格式的规范性
export function Verify_Mobile(value) {
  return /^1[0-9][0-9]\d{8,8}$/.test(value);
}
//验证手机/电话号码格式的规范性
export function Verify_MT(value) {
  if (Verify_Mobile(value) || Verify_Telephone(value)) {
    return true;
  } else {
    return false;
  }
}
//验证邮政编码格式的规范性：6位码第一位不能是0
export function Verify_ZipCode(value) {
  return /^[1-9]\d{5}$/.test(value);
}
//验证QQ格式的规范性：至少5位，第一位也不能为0
export function Verify_QQ(value) {
  return /^[1-9]\d{4,}$/.test(value);
}
//验证邮箱
export function Verify_mailbox(value) {
  // eslint-disable-next-line no-useless-escape
  return /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value);
}

//验证身份证格式的规范性：开头是14位或者17位数字，结尾可以是数字或者是x或者是X
export function Verify_IDCard(value) {
  return /^(\d{14}|\d{17})(\d|[xX])$/.test(value);
}
//验证日期格式的规范性：yyyy-mm-dd Or yyyy.mm.dd
export function Verify_Date(value) {
  //return /^1\d{3}(-|\/)\d{1,2}(-|\.)\d{1,2}$/.test(value);
  // eslint-disable-next-line no-useless-escape
  return /((^((1[8-9]\d{2})|([2-9]\d{3}))([-\/\._])(10|12|0?[13578])([-\/\._])(3[01]|[12][0-9]|0?[1-9])$)|(^((1[8-9]\d{2})|([2-9]\d{3}))([-\/\._])(11|0?[469])([-\/\._])(30|[12][0-9]|0?[1-9])$)|(^((1[8-9]\d{2})|([2-9]\d{3}))([-\/\._])(0?2)([-\/\._])(2[0-8]|1[0-9]|0?[1-9])$)|(^([2468][048]00)([-\/\._])(0?2)([-\/\._])(29)$)|(^([3579][26]00)([-\/\._])(0?2)([-\/\._])(29)$)|(^([1][89][0][48])([-\/\._])(0?2)([-\/\._])(29)$)|(^([2-9][0-9][0][48])([-\/\._])(0?2)([-\/\._])(29)$)|(^([1][89][2468][048])([-\/\._])(0?2)([-\/\._])(29)$)|(^([2-9][0-9][2468][048])([-\/\._])(0?2)([-\/\._])(29)$)|(^([1][89][13579][26])([-\/\._])(0?2)([-\/\._])(29)$)|(^([2-9][0-9][13579][26])([-\/\._])(0?2)([-\/\._])(29)$))/gi.test(
    value
  );
}
//验证价格格式的规范性：开头数字若干位，可能有一个小数点，小数点后面可以有两位数字
export function Verify_Price(value) {
  return /^(\d*\.\d{0,2}|\d+).*$/.test(value);
}

//验证姓名格式的规范性：要么是中文，要么是英文，不能混用
export function Verify_RealName(value) {
  if (value == "") return false;
  var chs = /^[\u4e00-\u9fa5]{2,4}$/; //2-4汉字
  var eng = /^(?!\s|.*\s$|.*\s{2,})(?=.*\s)(?=(.*[a-zA-Z]){5,})[a-zA-Z\s]+$/; //英文姓名至少5个字符，且包含至少1个空格
  if (!(chs.test(value) || eng.test(value))) {
    return false;
  }
  return true;
}

//验证公司名格式的规范性 只能包含汉字，英文，数字，中划线，4字符以上
export function Verify_Company(value) {
  // eslint-disable-next-line no-useless-escape
  return /^([\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[-\a-zA-Z0-9]|[\s]){4,}$/gi.test(
    value
  );
}

//验证地址格式的规范性 只能包含汉字，英文，数字，中划线，6字符以上
export function Verify_Address(value) {
  // eslint-disable-next-line no-useless-escape
  return /^([\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[-\a-zA-Z0-9]|[\s]){6,}$/gi.test(
    value
  );
}

//验证字符串长度的规范性：1-255字符
export function Verify_String255(value) {
  return /^([\s\S]){1,255}$/.test(value);
}

//验证一定长度字符串（含汉字，汉字算2字符）
export function Verify_Leng(value, length) {
  // eslint-disable-next-line no-control-regex
  if (value.replace(/[^\x00-\xff]/g, "**").length < length) return false;
  else return true;
}

//验证：只能包含汉字，英文，数字，下划线
export function Verify_Tag(value) {
  //第一部分：汉字   第二部分：半角   第三部分：数字和英文
  // eslint-disable-next-line no-useless-escape
  return /^([\u4E00-\u9FA5]|[\uFE30-\uFFA0]|[_\a-zA-Z0-9]|[\s]){1,10}$/gi.test(
    value
  );
}
/*========================================================================*/
/*                  常用函数
  /*========================================================================*/
export function in_array(value, arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == value) return true;
  }
  return false;
}

// eslint-disable-next-line no-unused-vars
export function in_number(value, arr) {
  return /^-?\d+\.?\d{0,1}$/.test(value);
}

// eslint-disable-next-line no-unused-vars
export function in_num(value, arr) {
  return /(^[1-9]\d*$)/.test(value);
}
// }

// export default validate;
// {
// validate
// Verify_Empty,  //验证是否为空
// isEmptyObject, //对象是否为空
// Verify_Email,   //验证email格式的规范性
// Verify_UserName,  //验证用户名格式的规范性 只能包含英文，数字，下划线，6-16位
// Verify_NickName,  //验证昵称格式的规范性 只能由大小写英文字母、中文、数字组成
// Verify_Password,  //验证用户密码格式的规范性：6-20位，区分大小写
// Verify_GroupName,  //验证圈子名称格式的规范性 只能包含汉字，英文，数字，下划线，2字符以上
// Verify_WeiboNumber,  //验证微博号格式的规范性：至少3位，第一位也不能为0
// Verify_URL,               //验证URL格式的规范性：http(s)://www.xxx.xxx
// Verify_Path,           //验证路径格式的规范性：匹配象/path/path/的格式,必须以/开头/结尾
// Verify_Digital,     //验证全数字格式的规范性
// Verify_Digitel,     //验证全数字格式的规范性，第一位不能为0
// Verify_Chinese,    //验证汉字格式的规范性
// Verify_Telephone,   //验证电话号码格式的规范性  ：“XXXX-XXXXXXX”，“XXXX-XXXXXXXX”，“XXX-XXXXXXX”，   “XXX-XXXXXXXX”，“XXXXXXX”，“XXXXXXXX”。
// Verify_Mobile,   //验证手机号码格式的规范性
// Verify_MT,           //验证手机/电话号码格式的规范性
// Verify_ZipCode,  //验证邮政编码格式的规范性：6位码第一位不能是0
// Verify_QQ,          //验证QQ格式的规范性：至少5位，第一位也不能为0
// Verify_IDCard,   //验证身份证格式的规范性：开头是14位或者17位数字，结尾可以是数字或者是x或者是X
// Verify_Date,      //验证日期格式的规范性：yyyy-mm-dd Or yyyy.mm.dd
// Verify_Price,  //验证价格格式的规范性：开头数字若干位，可能有一个小数点，小数点后面可以有两位数字
// Verify_RealName,  //验证姓名格式的规范性：要么是中文，要么是英文，不能混用
// Verify_Company,    //验证公司名格式的规范性 只能包含汉字，英文，数字，中划线，4字符以上
// Verify_Address,    //验证地址格式的规范性 只能包含汉字，英文，数字，中划线，6字符以上
// Verify_String255,  //验证字符串长度的规范性：1-255字符
// Verify_Leng,           //验证一定长度字符串（含汉字，汉字算2字符）
// Verify_Tag,             //验证：只能包含汉字，英文，数字，下划线
// }
