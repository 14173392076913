<template>
<div>
<div class="invoice-window" :class="attr.cartAttr === true ? 'on' : ''">
	<div class="title-head">
        发票<span class="iconfont icon-guanbi" @click="closeAttr"></span>
    </div>
    <div class="invi-container">
		<p class="title">选择发票类型</p>
		<p class="tips">请务必谨慎选择发票类型，以及正确填写发票信息，发票开出后，不予更换</p>
	</div>
	<div class="select-type">
        <div class="checkbox-wrapper">
          <label class="well-check">
            <input
              type="checkbox"
              name=""
              @change="singleChecked"
              value="1"
              v-model="checkedCode"
            />
            <i class="icon"></i>
          </label>
        </div>
		<p>开发票</p>
	</div>
    <div class='invoice-menu acea-row row-between' v-if="checkedCode.length!=0">
		<div class="menu" :class="item.type==type? 'on':''" v-for="(item,index) in menuData" :key="index" @click="bindMenuChange(item.type)">
            <p>{{item.title}}</p>
            <div class='tee'>
            	<p v-if="item.type==0">不可抵税</p>
            </div>
		</div>
		<!-- <div class="menu">
            <p>专用发票(增值税)</p>
		</div> -->
	</div>
	<div class="purpose acea-row row-right">
		<p>*专用发票于每月统一开出</p>
	</div>
	<div class="invoice-list" v-if="checkedCode.length!=0">
		<div class="list tit">
			<p>填写发票信息</p>
		</div>
		
		<div class="list acea-row row-between">
			<p>发票抬头</p>
			<input type="text" v-model="invoice_rice" placeholder="请输入公司的名称全称" />
		</div>
		<div class="list acea-row row-between">
			<p>税号</p>
			<input type="text" v-model="duty_number" placeholder="请输入纳税人识别号" />
		</div>	
		<div v-if="type==1">
			<div class="list acea-row row-between">
				<p>地址</p>
				<input type="text" v-model="address" placeholder="公司单位注册地址" />
			</div>
			<div class="list acea-row row-between">
				<p>联系电话</p>
				<input type="text" v-model="phone" placeholder="请输入联系电话" />
			</div>
			<div class="list acea-row row-between">
				<p>开户银行</p>
				<input type="text" v-model="blank_name" placeholder="公司单位开户银行全称" />
			</div>
			<div class="list acea-row row-between">
				<p>银行账号</p>
				<input type="text" v-model="blank_account" placeholder="请输入开户银行账号" />
			</div>
		</div>	
	</div>
	<div class="detail-btn acea-row row-between" v-if="checkedCode.length!=0 && !id">
	    <div class="right pay bule" :class="firstJum? 'glay':''" @click="save">
	        <p>保存</p>
	    </div>
    </div>
    <div class="detail-btn acea-row row-between" v-if="id">
	    <div class="right pay bule" :class="firstJum? 'glay':''" @click="done">
	        <p>完成</p>
	    </div>
    </div>
</div>
<div
  class="mask"
  :hidden="attr.cartAttr === false"
  @click="closeAttr"
></div>	
</div>
</template>

<script type="text/javascript">
import { postSave,getInvoice } from "@api/user";
import {Verify_Empty,Verify_Mobile} from '@utils/dy_validate';
export default {
	name:'InvoicePopu',
	props: {
	    attr: {
	      type: Object,
	      default: () => {}
	    },
	    storeInfo: {
	      type: Object,
	      default: () => {}
	    },
	    typename: {
	      type: String,
	      default: () => {}
	    }
	},
	data(){
		return {
            checkedCode:[],
			firstJum:false,
			id:'',
			invoice_rice:'',
			duty_number:'',
			address:'',
			blank_name:'',
			blank_account:'',
			phone:'',
			type:'1',
			menuData:[
				{type:'0',title:'普通发票(增值税)'},
				{type:'1',title:'专用发票(增值税)'}
			]
		}
	},
	mounted(){
		this.getInvoice();
	},
	methods:{
		bindMenuChange(type){
			var that = this;
			that.type = type;
			that.getInvoice()
		},
		getInvoice(){
	    	var that = this;
	    	var data = {
	    		type:that.type,
	    	}
	    	getInvoice(data).then(res=>{
	    		that.id = res.data.id;
	    		that.invoice_rice = res.data.invoice_rice;
				that.duty_number = res.data.duty_number;
				that.address = res.data.address;
				that.blank_name = res.data.blank_name;
				that.blank_account = res.data.blank_account;
				that.phone = res.data.phone;
	    	}).catch(res=>{
	    		console.log(res);
	    	})
	    },
	    //点击提交
	    save(){
	    	var that = this;
	    	if(that.firstJum==false) {
	    		if(that.type==1) {
					if(Verify_Empty(that.invoice_rice)) {
			    		this.$dialog.toast({ mes: "请输入发票抬头" });
						return false;
			    	} else if(Verify_Empty(that.duty_number)) {
			    		this.$dialog.toast({ mes: "请输入税号" });
						return false;
			    	} else if(Verify_Empty(that.address)) {
			    		this.$dialog.toast({ mes: "请输入地址" });
						return false;
			    	} else if(Verify_Empty(that.blank_name)) {
			    		this.$dialog.toast({ mes: "请输入开户银行" });
						return false;
			    	} else if(Verify_Empty(that.blank_account)) {
			    		this.$dialog.toast({ mes: "请输入银行账号" });
						return false;
			    	} else if(Verify_Empty(that.phone)) {
			    		this.$dialog.toast({ mes: "请输入联系电话" });
						return false;
			    	} else if(!Verify_Mobile(that.phone)) {
			    		this.$dialog.toast({ mes: "请输入正确的手机号" });
						return false;
			    	}
	    		} else {
	    			if(Verify_Empty(that.invoice_rice)) {
			    		this.$dialog.toast({ mes: "请输入发票抬头" });
						return false;
			    	} else if(Verify_Empty(that.duty_number)) {
			    		this.$dialog.toast({ mes: "请输入税号" });
						return false;
			    	}
	    		}
				
		    	that.firstJum =  true;
		    	var data = {
		    		invoice_rice:that.invoice_rice,
					duty_number:that.duty_number,
					address:that.address,
					blank_name:that.blank_name,
					blank_account:that.blank_account,
					phone:that.phone,
					type:that.type,
		    	}
		    	postSave(data).then(res=>{
		    		that.firstJum = false;
		    		this.$dialog.success(res.msg);
		    		that.getInvoice();
		    	}).catch(res=>{
		    		that.firstJum =  false;
		    		 this.$dialog.error(res.msg);
		    	})

	    	} 

	    },
		//选择是否需要开发票
		singleChecked(){
            var that = this;
            console.log(that.checkedCode.length);
		},
		done(){
			var that = this;
			this.$emit("changeFun2", {
		        action: "InvoicePopu",
		        value: false,
		        checkedCode:that.checkedCode.length,
		        inId:that.id,
		    });
		},
		//关闭弹窗
		closeAttr: function() {
		    this.$emit("changeFun2", {
		        action: "InvoicePopu",
		        value: false,
		    });
	    }
	}
}
</script>