<template>
  <div>
    <div class="address-window" :class="value === true ? 'on' : ''">
      <div class="title">
        配送方式<span class="iconfont icon-guanbi" @click="closeAddress"></span>
      </div>
      <div class="list">
        <div
          class="item expressType acea-row row-between-wrapper"
          v-for="(item, index) in checked"
          @click="tapAddress(index)"
          :key="index"
        >
          <div class="addressTxt">
            <div
              class="name"
            >
              {{ item.name }}
            </div>
          </div>
          <span
            class="iconfont icon-complete" 
          ></span>
        </div>
      </div>
    </div>
    <div
      class="mask"
      @touchmove.prevent
      :hidden="value === false"
      @click="closeAddress"
    ></div>
  </div>
</template>
<script>
import { getAddressList } from "../api/user";
export default {
  name: "AddressWindow",
  props: {
    value: Boolean,
    checked: Array,
  },
  data: function() {
    return {
      addressList: [],
      current: 0,
      cartId: 0,
      pinkId: 0,
      couponId: 0
    };
  },
  mounted: function() {},
  methods: {
    getAddressList: function() {
      let that = this;
      getAddressList().then(res => {
        that.addressList = res.data;
      });
    },
    closeAddress() {
      this.$emit("input", false);
    },
    goAddressPages: function() {
      this.$router.push({ path: "/user/add_address" });
      this.$emit("redirect");
    },
    tapAddress: function(index) {
      this.$emit("checked", this.checked[index]);
      this.$emit("input", false);
    }
  }
};
</script>
